function globalblue() {
  var place = $('input[name="place_id"]').val();
  var weight = $('.globalblue input[name="weight"]').val();
  var package_count = $('.globalblue input[name="package_count"]').val();
  var url = $('.product.globalblue').attr('data-url');
  var products = '';
  var extra_costs = 0;

  $('.globalblue .product_type input:checked').each(function(){
    products += $(this).val() + ', ';
    extra_costs += parseInt($(this).attr('data-cost'));
  });

  $('input[name="products"]').val(products);
  $('input[name="items"]').val(package_count);

  if(weight.length > 0 && place.length > 0 ) {
    var packs = [];
    var data = {};
    data.length = 0;
    data.width = 0;
    data.height = 0;
    data.weight = weight;
    data.count = 1;
    data.content = products;

    packs.push(data);
    console.log(extra_costs);

    $.ajax({
        type: "POST",
        url: url + '/ajax/call.php',
        data: {
          placeID: place,
          packs: packs,
          type: 'globalblue'
        },                
        success: function(val){
          console.log(val);
          if(val !== 'false') {
              var data = jQuery.parseJSON(val);
              var html = '';
              var tax = 1;

              $.each(data, function(k, v) {
                if(v !== 'false' && v !== 'quotation'){
                  $('.no_value').hide();
                  $('.type_send').show();
                  $('.on_request').hide();

                  if(typeof data.info.region.length !== 'undefined'){
                    console.log(data.info.region);
                    if(data.info.region === 'Europe' || data.info.region === 'europe') {
                      console.log('123');
                      $('.product.woocommerce').attr('data-tax', '1.21');
                      tax = 1.21;
                    } else {
                      $('.product.woocommerce').attr('data-tax', '1');
                      tax = 1;
                    }
                  }

                  if(typeof v.courier_true !== 'undefined'){
                    if(v.courier_true == 'false') {
                      $('.modal-koerier-big').show();
                    } else {
                      $('.modal-koerier-big').hide();
                    }
                  }

                  if(v.type == 'courier') {  
                    total = parseFloat(Math.round((parseInt(v.price) + parseInt(extra_costs)) * 100) / 100).toFixed(2);
                    $('.type_send #econ').show();
                    $('#econ span').html('Economy (&euro;'+ (total) + ')');
                    $('#econ input').attr('data-price', parseInt(v.price) + parseInt(extra_costs));
                  }

                  if(v.type == 'courier_prio') {
                    total = parseFloat(Math.round(((parseInt(v.price) + parseInt(extra_costs)) * tax) * 100) / 100).toFixed(2);
                    $('.type_send #prio').show();
                    $('#prio span').html('Priority (&euro;'+ (total) + ')');
                    $('#prio input').attr('data-price', parseInt(v.price) + parseInt(extra_costs));
                  }

                  $('input[name="total_price"]').val($('input[name="send_type"]:checked').attr('data-price'));
                  
                } else {
                  $('.on_request').show();
                  $('.type_send').hide();
                  $('.type_send #econ').hide();
                  $('.type_send #prio').hide();
                }

              });
          } else {
            $('.no_value').show();
            $('.on_request').hide();
            $('.type_send').hide();
            $('.type_send #econ').hide();
            $('.type_send #prio').hide();
            $('input[name="total_price"]').val(0);
          }
        }
    });

  }
}

$('.globalblue select[name="pickup_location"]').change(function(){
  if($(this).val() == 'other') {
    $('.globalblue .other_pickup').show();
  } else {
    $('.globalblue .other_pickup').hide();
  }

  $('.checkout_slider').css('height', $('.slide.inner.active').outerHeight());
});

$('.globalblue .add_file').click(function(){
  var cur = $('.globalblue .files input').length;
  var count = (parseInt($(this).attr('data-files')) + 1);
  var input = '';
  var i = cur;

  if(cur < 20) {
    while (i < count) {
      input += '<input type="file" name="file_'+ count +'" value="" class="valid">';
      i++;
    }

    $(".globalblue .files").append(input);
    $(this).attr('data-files', count);

    $('.checkout_slider').css('height', $('.slide.inner.active').outerHeight());
  }
});

$('.globalblue input[name="package_count"]').change(function(){
  globalblue();
});

$('.globalblue .product_type input').change(function(){
  globalblue();
});

$('.globalblue input[name="weight"]').change(function(){
  globalblue();
});

$('.globalblue input[name="city"]').change(function(){
  globalblue();
});

$('.globalblue input[name="send_type"]').change(function(){
  if($(this).is(':checked')) {
    $('input[name="total_price"]').val($(this).attr('data-price'));
  }
});